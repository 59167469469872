<template>
    <section>
        <a-form class="form-container" :form="form">
            <a-row type="flex" :gutter="20">
                <a-col :span="8">
                    <a-form-item label="作业编号" >
                        <a-input placeholder="请输入" v-decorator="['no',{rules:[{required:true,message:'请输入作业编号'},{validator:Validator.numberReg},{max:20,message:'最大输入20个字符'}]}]" />
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="作业类型" >
                        <a-select placeholder="请选择" v-decorator="['type',{rules:[{required:true,message:'请选择作业类型'}]}]">
                            <a-select-option v-for="(item,key) in typeList" :key="key" :value="item.id">{{item.name}}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row  type="flex" :gutter="20">
                <a-col :span="8">
                    <a-form-item label="申请单位 " >
                        <a-select v-model="applyCompany" disabled>
                            <a-select-option v-for="item in companyMap" :key="item.id" :value="item.id">{{item.companyName}}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="申请人" >
                        <a-input v-model="applyPersonName" disabled />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="20">
                 <a-col :span="8">
                    <a-form-item label="作业时间 " >
                        <a-range-picker v-decorator="['endTime',{rules:[{required:true,message:'请选择作业时间'}]}]" />
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="作业部位" >
                        <a-input v-decorator="['position',{rules:[{required:true,message:'请输入作业部位'},{max:50,message:'最大输入50个字符'}]}]" />
                    </a-form-item>
                </a-col>
            </a-row>
             <a-row>
                <a-col :span="16">
                    <a-form-item label="作业描述" >
                        <a-textarea rows="4" v-decorator="['description',{rules:[{required:true,message:'请输入作业描述'},{max:200,message:'最大输入200个字符'}]}]"></a-textarea>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row type="flex" :gutter="20">
                 <a-col :span="8">
                    <a-form-item label="监管人 " >
                        <a-select :disabled="!!rid" mode="multiple" v-decorator="['supervisePerson',{rules:[{required:true,message:'请选择监管人'}]}]">
                            <a-select-option v-for="(item,key) in supervisePersonList" :key="key" :value="item.userId">{{item.name}}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="通知人" >
                        <a-tree-select
                            style="width: 100%"
                            :dropdownStyle="{height:'400px'}"
                            :tree-data="userTreeData"
                            tree-checkable
                            treeCheckStrictly
                            search-placeholder="请选择"
                            v-decorator="['notiPerson',{rules:[{required:false,message:'请选择通知人'}]}]" 
                        />
                        <!-- <a-select mode="multiple" placeholder="" v-decorator="['notiPerson',{rules:[{required:false,message:'请选择通知人'}]}]">
                            <a-select-option v-for="(item,key) in userList" :key="key" :value="item.id">{{item.name}}</a-select-option>
                        </a-select> -->
                    </a-form-item>
                </a-col>
            </a-row>
           <a-row>
                <a-col :span="16">
                    <a-form-item label="作业申请表" >
                        <a-row type="flex">
                            <a-row class="upload-preview-wrap">
                                <a v-for="(item,key) in fileLists" :key="key" class="file-item m-r-10">
                                    <preview-image :data="item" :size="100" />
                                    <a class="close-icon" title="删除" @click="deleteFile(item)"><a-icon type="close"></a-icon></a>
                                </a>
                            </a-row>
                            <upload style="display:inline-block;width:100px" 
                            @func="getFile" 
                            :node="{type:3,text:'上传'}" 
                            v-decorator="['file',{rules:[{required:true,message:'请上传作业申请表'}]}]" 
                            :accept="['.png','.jpg']"/>
                        </a-row>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row class="form-btn-group">
                <a-col :span="16">
                    <a-button @click="submit" :disabled="submitLoadding" :loading="submitLoadding"><a-icon v-if="!submitLoadding" type="save" />保存</a-button>
                    <a-button @click="$router.back()">取消</a-button>
                </a-col>
            </a-row>
        </a-form>
    </section>
</template>

<script>
import moment from 'moment'
import upload from '@/components/upload'
import Validator from '@/until/validator'
import previewImage from '@/components/previewImage'
// import selectUserCascader from '@/components/select-user-cascader'
export default {
    data(){
        return{
            Validator,
            typeList:[],
            userList:[],
            userTreeData:[],
            fileLists:[],
            companyMap:[],
            supervisePersonList:[],
            applyCompany:'',
            applyPersonName:'',
            applyPerson:''

        }
    },
    components:{upload,previewImage},
    computed:{
        rid(){
            return this.$route.query.id
        },
        picServer(){
            return this.$store.state.picServer
        },
        userNickname(){
            return this.$store.state.userInfo.userNickname
        },
        userId(){
            return this.$store.state.userInfo.userId
        },
        companyId(){
            return this.$store.state.userInfo.companyId
        },
        submitLoadding(){
            return this.$store.state.submitLoadding
        }
    },
    beforeCreate(){
        this.form = this.$form.createForm(this)
    },
    created(){
        
        this.init()
        if(!this.rid){
            this.applyCompany = this.companyId
            this.applyPersonName = this.userNickname
            this.applyPerson = this.userId
        }
    },
    methods:{
        init(){
            this.querySafeJobTypeList()
            //this.queryUserDropDownBox()
            this.queryCompanyDropDownBox()
            this.querySafeOccupationUserList()  //监管职务下的人员列表
            this.queryUserOptionsDialog()   //通知人下拉列表
        },
        //单位下拉
        queryCompanyDropDownBox(){
            this.$api.queryCompanyDropDownBox().then(res =>{
                if(res.code === 200){
                    this.companyMap = res.data || []
                }
            })
        },
        //监管职务下的人员列表
        querySafeOccupationUserList(){
            this.$api.querySafeOccupationUserList().then(res =>{
                if(res.code === 200){
                    this.supervisePersonList = res.data || []
                }
            })
        },
        //作业类型下拉列表
        querySafeJobTypeList(){
            this.$api.querySafeJobTypeList().then(res =>{
                
                if(res.code === 200){
                    this.typeList = (res.data && res.data.records) || []
                }
            })
        },
        queryUserDropDownBox(){
            this.$api.queryUserDropDownBox().then(res =>{
                if(res.code === 200){
                    this.userList = res.data || []
                }
            })
        },
        //用户树下拉接口
        queryUserOptionsDialog(){
            this.$api.queryUserOptionsDialog().then(res =>{
                if(res.code === 200){
                   
                    let data = res.data || []
                    data.forEach(item =>{
                        item.title = item.companyName
                        item.value = item.id
                        item.key = item.id
                        item.checkable = false
                        item.children = item.user ? item.user : []
                        if(item.children.length){
                            item.children.forEach(v =>{
                                v.title = v.name
                                v.value = v.id
                                v.key = v.id
                            })
                        }
                    })
                    this.userTreeData = data
                    this.rid && this.querySafeJobDetails()
                }
            })
        },
        getFile(file){
            this.fileLists.push(file.codeUrl)
            this.$nextTick(() =>{
                this.form.setFieldsValue({
                    file:this.fileLists.length ? this.fileLists.join(',') : ''
                })
            })
        },
        deleteFile(list){
            this.fileLists = this.fileLists.filter(item => item != list)
            this.$nextTick(() =>{
                this.form.setFieldsValue({
                    file:this.fileLists.length ? this.fileLists.join(',') : ''
                })
            })
        },
        querySafeJobDetails(){
            this.$api.querySafeJobDetails(this.rid).then(res =>{
                if(res.code === 200){
                    
                    let info = res.data || {}
                    let endTime = [moment(info.startTime),moment(info.endTime)]
                    let formData = {
                        no:info.no,
                        type:info.type,
                        // applyCompany:info.applyCompany,
                        // applyPerson:info.applyPerson,
                        position:info.position,
                        description:info.description,
                        endTime,
                        supervisePerson:info.supervisePerson ? info.supervisePerson.split(',') : [],
                        notiPerson:info.notiPerson ? info.notiPerson.split(',') : [],
                    }
                    this.applyCompany = info.applyCompany

                    let notiPerson = []
                    this.userTreeData.forEach(item =>{
                        if(item.children && item.children.length){
                            item.children.forEach(v =>{
                                if(info.notiPerson && info.notiPerson.includes(v.id)){
                                    notiPerson.push({label:v.name,value:v.id})
                                }
                            })
                        }
                    })
                    formData.notiPerson = notiPerson
                    
                    this.applyPersonName = info.applyPersonName
                    this.applyPerson = info.applyPerson
                    // this.fileLists =  info.file ? JSON.parse(info.file) :[]
                    this.fileLists = info.file ? info.file.split(',') : []
                    formData.file = this.fileLists.length ? this.fileLists.join(',') : ''
                    this.form.setFieldsValue(formData)
                }
            })
        },
        //新增和编辑提交服务器
        submit(){
            this.form.validateFields((err,formData) =>{
                if(!err){
                    this.$store.commit('SET_SUBMITLOADDING',true)
                    formData.startTime = formData.endTime[0].format('YYYY-MM-DD')
                    formData.endTime = formData.endTime[1].format('YYYY-MM-DD')
                    formData.supervisePerson = formData.supervisePerson.join(',')
                    formData.notiPerson = formData.notiPerson ? formData.notiPerson.map(item => item.value).join(',') : ''
                    //formData.file = this.fileLists.length ? JSON.stringify(this.fileLists) : ''
                    //formData.file = this.fileLists.join(',')
                    formData.applyCompany = this.applyCompany
                    formData.applyPerson = this.applyPerson
                   
                    if(this.rid){
                        formData.id = this.rid
                    }
                    let apiName = this.rid ? 'updateSafeJob' : 'insertSafeJob'
                    this.$api[apiName](formData).then(res =>{
                        if(res.code === 200){
                            this.$router.back()
                        }
                    })
                }
            })
        }
    }
}
</script>